export default {
    name: 'transferTree',
    data () {
        return {
            searchBoolean: true,
        }
    },
    props: {
        search: {
            type: Boolean,
            default: () => true
        },
        model: {
            type: Object,
            default: () => {}
        },
    },
    components: {},
    created () {},
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {
        search(newVal) {
            //判断传进来的数据是否已启用搜索--false(启用)true(失效)
            this.searchBoolean = newVal;
        },
    },
    computed: {},
    filters: {
        getListNumFilters(list) {
            let num = 0
            let eachList = (listItem) => {
                if (listItem.userList && listItem.userList.length > 0) {
                    // num = num + listItem.workerList.length
                    listItem.userList.forEach(value => {
                        if (value.canCheck) {
                            num++
                        }
                    })
                }
                if (listItem.children && listItem.children.length > 0) {
                    listItem.children.forEach(val => {
                        eachList(val)
                    })
                }
            }
            eachList(list)
            return num
        }
    },
    methods: {
        searchIsExpandUserList(val){
            let box = false;
            val.userList.forEach((item,index) => {
                if (item.canCheck) {
                    box = true;
                }
            });
            if (val.children.length != 0) {
                val.children.forEach((item,index) => {
                    if (this.searchIsExpandUserList(item)) {
                        box = true;
                    }
                });
            }
            return box
        },
        searchIsExpand(val){
            //根据传入信息判断是否显示信息

            // 判断该部门及其子部门是否展示
            let searchSwitch = false;
            searchSwitch = this.searchIsExpandUserList(val);
            //一层：区分是否使用搜索功能
            if (this.searchBoolean) {
                //未使用搜索功能
                return val.isExpand
            } else {
                //已使用搜索功能
                if (searchSwitch) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        handlerExpand(val) {
            this.$emit('handlerExpand', val)
        },
        toSelect(val) {
            this.$emit('toSelect', val)
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/